<template>
    <div>
        <el-form :model="formData"
                 :rules="formRules"
                 ref="form"
                 size="mini">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'发送方式'"
                                  prop="receiveSettings">
                        <el-select v-model="formData.receiveSettings"
                                   filterable
                                   multiple
                                   :placeholder="'请选择'"
                                   style="width: 100%">
                            <el-option v-for="item in this.MessageSendTypeList"
                                       :key="item.key"
                                       :label="item.value"
                                       :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'MessagePushConfigurationBatchForm',
    props: {
        id: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: 'edit'
        },
        messageType: {
            type: String,
            default: ''
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            MessageSendTypeList: [],
            formData: {
                receiveSettings: [],
                userIds: this.id,
                messageType: this.messageType
            },
            formRules: {
                receiveSettings: [
                    {
                        required: true,
                        message: this.$t('common.select', {
                            item: this.$t(
                                'message-push-configuration.table.send-type'
                            )
                        }),
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    methods: {
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$client
                        .updateUserMessageSetting(this.formData)
                        .then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                }
            });
        }
    },
    created() {
        this.$enum('EMessageSendMethod').then(data => {
            this.MessageSendTypeList = data;
        });
    }
};
</script>
<style lang="less" scoped>
</style>


