<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'姓名'"
                                  prop="userId" v-if="type==='add'">
                        <user-select :includeVirtual='true' :defaultValue="formData.userId" v-model="formData.userId"></user-select>
                    </el-form-item>
                    <el-form-item :label="'姓名'"
                                  prop="userId"
                                  v-else>
                        <user-select :defaultValue="formData.userId"
                                     v-model="formData.userId"
                                     :multiple="false"
                                     :includeVirtual='true'
                                     :disabled="type==='edit'"
                                     :isStaff="true"></user-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'消息类型'"
                        prop="messageType"
                    >
                        <el-select
                            v-model="formData.messageType"
                            filterable
                            :placeholder="'请选择'"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in this.keyValues.MessageTypeList"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'发送方式'"
                        prop="receiveSettings"
                    >
                        <el-select
                            v-model="formData.receiveSettings"
                            filterable
                            multiple
                            :placeholder="'请选择'"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in this.keyValues.MessageSendMethodList"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import UserSelect from '@/components/UserSelect';
export default {
    name: 'MessagePushConfigurationForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        messageType: {
            type: String,
            default: ''
        },
        closeModal: {
            type: Function
        }
    },
    components: {
        UserSelect
    },
    data() {
        return {
            visible: false,
            LayoutCodeValue: '',
            LayoutCodeList: [],
            formData: {
                userId: '',
                receiveSettings: [],
                messageType: '',
                id: this.id
            },
            formRules: {
                userId: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }
                ],
                messageType: [
                    {
                        required: true,
                        message: '请选择消息类型',
                        trigger: 'blur'
                    }
                ],
                receiveSettings: [
                    {
                        required: true,
                        message: '请输入发送方式',
                        trigger: 'blur'
                    }
                ]
            },
            keyValues: {
                MessageTypeList: [],
                MessageSendMethodList: []
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([this.$enum('EMessageType'), this.$enum('EMessageSendMethod')]);
            this.keyValues.MessageTypeList = data[0] || [];
            this.keyValues.MessageSendMethodList = data[1] || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
            const {type, id} = this;
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getInfo();
            }
        },
        getInfo() {
            const queryData = {type: this.messageType, userIds: [this.id]};
            this.$client.queryUserMessageSetting(queryData).then(data => {
                this.formData = data.items[0];
            });
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.type === 'edit') {
                        const updateData = {userIds: [this.formData.userId], messageType: this.formData.messageType, receiveSettings: this.formData.receiveSettings};
                        this.$client.updateUserMessageSetting(updateData).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    } else {
                        const tmp = {...this.formData};
                        delete tmp.id;
                        this.$client.addUserMessageSetting(tmp).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>

