<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <el-button type="primary" size="mini" @click="addHandle">{{'新建'}}</el-button>
            <el-button type="primary" size="mini" @click="editHandle">{{'编辑'}}</el-button>
            <el-button type="primary" size="mini" @click="showDetail">{{'详情'}}</el-button>
            <el-button type="primary" size="mini" @click="deleteHandle">{{'删除'}}</el-button>
            <el-button
                type="primary"
                size="mini"
                @click="BulkUpdateHandle"
            >{{'批量修改发送方式'}}</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.queryUserMessageSetting"
                :columns="columns"
                :formOptions="formOptions"
                @selection-change="selectHandle"
                :paramsMethod="paramsMethod"
            ></ex-search-table-pagination>
        </div>
        <el-dialog
            width="700px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <message-push-configuration-form
                ref="editForm"
                :id="dialog.currentId"
                :type="dialog.type"
                :messageType="dialog.messageType"
            ></message-push-configuration-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">{{dialog.type === 'detail' ? '关闭' : '取消'}}</el-button>
                <el-button type="primary" @click="submitHandle" size="mini" v-if="dialog.type !== 'detail'">{{'确定'}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
            width="350px"
            :title="updateMethodDialog.title"
            v-if="updateMethodDialog.visible"
            :visible.sync="updateMethodDialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <message-push-configuration-batch-form
                ref="editForm"
                :id="updateMethodDialog.currentId"
                :type="updateMethodDialog.type"
                :messageType="updateMethodDialog.messageType"
            ></message-push-configuration-batch-form>
            <span slot="footer" class="updateMethodDialog-footer">
                <el-button @click="closeModalHandle" size="mini">{{'取消'}}</el-button>
                <el-button type="primary" @click="submitHandle" size="mini">{{'确定'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import MessagePushConfigurationForm from './MessagePushConfigurationForm';
import MessagePushConfigurationBatchForm from './MessagePushConfigurationBatchForm';
export default {
    name: 'MessagePushConfiguration',
    components: {
        MessagePushConfigurationForm,
        MessagePushConfigurationBatchForm
    },
    data() {
        return {
            messageType: '',
            MessageTypeList: [],
            loading: false,
            userList: [],
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                tyle: '',
                messageType: ''
            },
            updateMethodDialog: {
                visible: false,
                title: '',
                currentId: [],
                tyle: '',
                messageType: ''
            }
        };
    },
    computed: {
        columns() {
            return [
                {
                    type: 'selection'
                },
                {
                    prop: 'account',
                    label: '帐号'
                },
                {
                    prop: 'userName',
                    label: '姓名'
                },
                {
                    prop: 'orgName',
                    label: '部门'
                },
                {
                    prop: 'messageTypeName',
                    label: '消息类型'
                },
                {
                    prop: 'receiveSettingsName',
                    label: '发送方式'
                }
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'type',
                        itemType: 'select',
                        filterable: true,
                        label: '消息类型',
                        options: this.MessageTypeList,
                        rules: [
                            {
                                required: true,
                                message: '请选择消息类型',
                                trigger: 'blur'
                            }
                        ]
                    },
                    {
                        prop: 'userIds',
                        itemType: 'selectOrg',
                        clearable: true,
                        filterable: true,
                        valueKey: 'id',
                        labelKey: 'name',
                        remote: true,
                        loading: this.loading,
                        label: '姓名',
                        placeholder: '请输入姓名',
                        remoteMethod: this.selectSearchHandle,
                        options: this.userList
                    }
                ]
            };
        }
    },
    methods: {
        paramsMethod(params) {
            if (!params.userIds) {
                params.userIds = [];
            }
            this.messageType = params.type;
        },
        selectSearchHandle(key) {
            if (key === '') {
                return;
            }
            this.loading = true;
            const params = {
                key,
                staffType: '',
                isStaff: '',
                includeVirtual: true
            };
            this.$client.searchUser(params).then(data => {
                this.userList = data || [];
                this.loading = false;
            });
        },
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
            this.updateMethodDialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add',
                messageType: undefined,
            };
        },
        editHandle() {
            if (this.handleValidate('edit')) {
                this.dialog = {
                    currentId: this.selectedRows[0].userId,
                    title: '编辑',
                    visible: true,
                    type: 'edit',
                    messageType: this.selectedRows[0].messageType
                };
            }
        },
        deleteHandle() {
            if (this.handleValidate('delete')) {
                this.$confirm('确认删除？', {
                    type: 'warning'
                }).then(() => {
                    const {selectedRows} = this;
                    const userIds = selectedRows.map(item => item.userId);
                    const deletData = {userIds, messageType: selectedRows[0].messageType};
                    this.$client.deleteUserMessageSetting(deletData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.$refs.searchTable.searchHandler();
                    });
                });
            }
        },
        BulkUpdateHandle() {
            if (this.selectedRows.length > 0) {
                const {selectedRows} = this;
                this.updateMethodDialog = {
                    currentId: selectedRows.map(item => item.userId),
                    title: '编辑',
                    visible: true,
                    type: 'edit',
                    messageType: this.messageType
                };
            } else {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
        },
        showDetail() {
            if (this.handleValidate('detail')) {
                this.dialog = {
                    currentId: this.selectedRows[0].userId,
                    title: '详情',
                    visible: true,
                    type: 'detail',
                    messageType: this.selectedRows[0].messageType
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    },
    created() {
        this.selectSearchHandle();
        this.$enum('EMessageType').then(data => {
            this.MessageTypeList = data;
        });
    }
};
</script>

