<template>
    <el-select
        :remote="true"
        :loading="loading"
        v-bind="mergeProps"
        v-on="$listeners"
        @change="sendUser"
        @input="inputHandle"
        v-model="selected"
        :remoteMethod="selectSearchHandle"
        :disabled="disabled"
        :collapse-tags="collapseTags"
    >
        <el-option
            v-for="user in userList"
            :key="user.id"
            :label="user.name"
            :value="user.id"
            :disabled="optionDisabled(user)"
        >
            <div>
                <span
                    class="option-user-name"
                    :class="{'is-delete': user.isDeleted, 'is-forbidde': user.state === 'N'}"
                >{{user.name}}</span>
                <span class="option-user-org">{{user.orgs.join('、')}}</span>
            </div>
        </el-option>
    </el-select>
</template>
<script>
export default {
    name: 'UserSelect',
    data() {
        return {
            loading: false,
            userList: [],
            selected: this.multiple ? [] : '',
            flag: false
        };
    },
    props: {
        remote: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: true
        },
        filterable: {
            type: Boolean,
            default: true
        },
        staffType: {
            type: String,
            default: ''
        },
        isStaff: {
            type: Boolean,
            default: null
        },
        includeVirtual: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: [String, Array]
        },
        disabled: {
            type: Boolean,
            default: false
        },
        collapseTags: {
            type: Boolean,
            default: false
        },
        optionDisabled: {
            type: Function,
            default: user => {
                return user.isDeleted || user.state === 'N';
            }
        },
        value: {
            default: ''
        },
        withPrivilege: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        mergeProps() {
            return {
                ...this.$props,
                ...this.$attrs,
                value: undefined
            };
        }
    },
    methods: {
        sendUser(v) {
            const user = this.userList.find(a => a.id === v);
            this.$emit('sendUser', user);
        },
        selectSearchHandle(key) {
            if (key === '' || key == null) {
                return;
            }
            this.loading = true;
            const params = {
                key,
                staffType: this.staffType,
                isStaff: this.isStaff,
                includeVirtual: this.includeVirtual
            };

            if (this.withPrivilege) {
                this.$client.searchUserWithPrivilege(params).then(data => {
                    this.userList = data || [];
                    this.loading = false;
                });
            } else {
                this.$client.searchUser(params).then(data => {
                    this.userList = data || [];
                    this.loading = false;
                });
            }
        },
        backfillSelected(value, oldValue) {
            //防止重复监听处理同一个value
            if (this.flag) {
                this.flag = false;
                return;
            }
            //清空逻辑
            if (oldValue && oldValue !== '' && value === '') {
                this.selected = value;
            }
            if (value) {
                const params = {
                    key: '',
                    staffIds: [value],
                    staffType: this.staffType,
                    isStaff: this.isStaff
                };
                this.$client.searchUser(params).then(data => {
                    this.userList = data || [];
                    this.loading = false;
                    this.selected = value;
                    this.sendUser(value);
                });
            }
        },
        inputHandle(val) {
            this.$emit('input', val);
            this.flag = true;
        }
    },
    created() {
        this.$watch('value', this.backfillSelected);
    }
};
</script>
<style lang="less" scoped>
.is-delete {
    text-decoration: line-through;
}
.is-forbidden {
    color: red;
}
.option-user-name {
    float: left;
}
.option-user-org {
    margin-left: 15px;
    float: right;
    color: #8492a6;
    font-size: 13px;
    margin-right: 12px;
}
</style>


